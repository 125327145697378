<template>
  <div class="open-api-box">
    <iframe src="/static/api.html" frameborder="0" width="100%" :height="height" scrolling="no"></iframe>
  </div>
 
</template>
<script>
  export default {
    name: "OpenApi",
    data() {
      return {
        height: 0
      }
    },
    mounted() {
      document.title = '4G平板锁第三方服务接口说明';
      this.height = document.documentElement.clientHeight;
    },
  }
</script>
